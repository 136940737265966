import React from "react";
import clock from "../assets/icons/clock.png";
import HiddenControls from "../components/HiddenControls";
import { useParams } from "react-router-dom";

const TimesupScreen = () => {
  const { groupId, questionId } = useParams();
  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls
        previous=""
        next={`/group/${groupId}/question/${questionId}/results`}
      />
      <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
        <h1 className="zeroSecText w-75 mx-auto">0 Seconds</h1>
        <div className="timesUpTextContainer bottomShadow bottomLightBlueShadow d-flex justify-content-center align-items-center">
          <img src={clock} alt="clock" width={80} height={80} />
          <span className="ms-4 timesUpText">Time Is Up!</span>
        </div>
      </div>
    </div>
  );
};

export default TimesupScreen;
