/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { useEffect } from "react";
import { useNavigate } from "react-router";

const HiddenControls = ({ next, previous }) => {
  const navigate = useNavigate();

  const goLeft = () => {
    previous !== "" && navigate(previous);
  };
  const goRight = () => {
    next !== "" && navigate(next);
  };

  useEffect(() => {
    const previusBtn = document.getElementById("previusBtn");
    const nextBtn = document.getElementById("nextBtn");
    document.addEventListener("keydown", (e) => {
      const key = e.key;
      key === "ArrowLeft"
        ? previusBtn.click()
        : key === "ArrowRight"
        ? nextBtn.click()
        : null;
    });
  }, []);

  return (
    <div
      className="position-absolute"
      style={{ bottom: 0, right: 50, visibility: "hidden" }}
    >
      <button id="previusBtn" onClick={() => goLeft()}>
        pr
      </button>
      <button id="nextBtn" onClick={() => goRight()}>
        nx
      </button>
    </div>
  );
};

export default HiddenControls;
