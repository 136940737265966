import React from "react";
import HiddenControls from "../components/HiddenControls";
import welcomeImage from "../assets/images/welcome.jpg";

const WelcomeScreen = () => {
  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls previous="/euflag" next="/intros" />
      <div className="h-100 mx-auto welcomeImageContainer">
        <img
          src={welcomeImage}
          alt="lets play"
          // width="100%"
          height="100%"
          className="welcomeImage"
        />
      </div>
    </div>
  );
};

export default WelcomeScreen;
