import axios from "axios";
import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import PersonCard from "../components/PersonCard";

const AllQualidiersScreen = () => {
  const [phase1Winner, setPhase1Winners] = useState([]);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/admin/v1/qualified`, {
          headers,
        })
        .then((res) => setPhase1Winners(res.data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls previous="" next="/phase2" />
      <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
        <div className="d-flex justify-content-between mx-5 mt-2 pb-5">
          <h2 className="headerQuestionTitleLeft text-start">Qualified</h2>
          <p className="numberQualifiers text-white me-3">4 Contestants</p>
        </div>
        <div className="d-flex flex-wrap mt-5 mx-5">
          {phase1Winner &&
            phase1Winner.map((winner, i) => {
              return (
                <PersonCard
                  key={i}
                  name={winner.name}
                  image={winner.image}
                  school={winner.school}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AllQualidiersScreen;
