import axios from "axios";
import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import PlayerScoreAndRankCard from "../components/PlayerScoreAndRankCard";
import { useParams } from "react-router-dom";

const G2ScoreScreen = ({ setQuestion }) => {
  const { questionId } = useParams();

  const [gameFinished, setGameFinished] = useState(false);
  const [playersData, setPlayersData] = useState([]);
  const [draw, setDraw] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(0);
  const [highScore, setHighScore] = useState(0);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/admin/v1/leaderboard`, {
          headers,
        })
        .then((res) =>
          res.data.success === false
            ? setPlayersData(res.data.data)
            : setPlayersData(res.data.data)
        );
    } catch (err) {
      console.log(err);
    }

    if (questionId >= 12) {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/admin/v1/winner`, { headers })
          .then(
            (res) => (
              setDraw(res.data.draw), setNextQuestion(res.data.next_question)
            )
          );
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    playersData.length > 0 && setHighScore(playersData[0].score);
  }, [playersData]);

  useEffect(() => {
    let q = parseInt(questionId);
    if (!draw && q === 12) {
      setGameFinished(true);
    } else if (q === 20) {
      setGameFinished(true);
    } else if (draw && q < 20) {
      setQuestion(q + 1);
      setGameFinished(false);
    } else if (!draw && q < 12) {
      setQuestion(q + 1);
      setGameFinished(false);
    } else if (!draw && q > 12) {
      setGameFinished(true);
    }
  }, [draw, questionId]);

  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls
        previous=""
        next={
          gameFinished
            ? "/winner"
            : `/phase2/questionStart/${parseInt(questionId) + 1}`
        }
      />
      <div className="h-100 text-center primary-bg pt-4 mainContainer">
        <h1 className="questionNumberTitle my-5">Score:</h1>
        <div className="pt-5">
          {playersData &&
            playersData.map((player, i) => {
              return (
                <PlayerScoreAndRankCard
                  key={i}
                  rank={i + 1}
                  score={player.score}
                  name={player.name}
                  image={player.image}
                  draw={draw}
                  highScore={highScore && highScore}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default G2ScoreScreen;
