import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

function LoginScreen() {
  const [errorMessages, setErrorMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [admin, setAdmin] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    try {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/admin/v1/auth/login`, {
          email: email,
          password: password,
        })
        .then((res) => {
          if (res.status === 200) {
            setAdmin(res.data);
            localStorage.setItem("accessToken", res.data.token);
            navigate("/euflag");
          } else {
            setErrorMessages("wrong credentials");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const renderForm = (
    <div className="form">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="input-container">
          <label>Username </label>
          <input type="email" name="email" required />
          {/* {renderErrorMessage("uname")} */}
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="password" required />
          {/* {renderErrorMessage("pass")} */}
        </div>
        <div className="text-danger mx-auto errorContainer">
          {errorMessages && errorMessages}
        </div>
        <div className="button-container">
          <input type="submit" value="Login" />
        </div>
      </form>
    </div>
  );

  return (
    <div className="loginPage">
      <div className="login-form">
        <div className="title">Sign In</div>
        {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
}

export default LoginScreen;
