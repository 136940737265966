import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AnswersRow from "../components/AnswersRow";
import HiddenControls from "../components/HiddenControls";
import { useNavigate } from "react-router";
import axios from "axios";

const G1QuestionScreen = ({ questions }) => {
  const { groupId, questionId } = useParams();

  const [time, setTime] = useState(20);
  const [done, setDone] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/groups/${groupId}/questions/${questionId}/initiate`,
          { headers }
        )
        .then((res) => console.log(res.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setCurrentQuestion(questions[questionId - 1]);
  }, [questionId, questions]);

  useEffect(() => {
    time === 0
      ? setDone(true)
      : setTimeout(() => {
          setTime(time - 1);
        }, 1000);
  }, [time]);

  useEffect(() => {
    done && navigate(`/group/${groupId}/question/${questionId}/timeup`);
  }, [done]);

  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls
        previous=""
        next={`/group/${groupId}/question/${questionId}/timeup`}
      />
      <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
        <div className="d-flex justify-content-between mx-3 mt-2">
          <h2 className="headerQuestionTitleLeft text-start">
            Question {questionId}
          </h2>
          <p
            className="headerTimerRight  me-3"
            style={time < 6 ? { color: "#F23838" } : { color: "white" }}
          >
            {time} sec
          </p>
        </div>
        <div className="row mx-2 my-2 ">
          {currentQuestion?.withBanner ? (
            <>
              <p className="col-8 p-0 questionText text-start">
                {currentQuestion?.question}
              </p>
              <div className="col-4 p-0 bannerContainer">
                <img
                  src={require(`../assets/images/game/${currentQuestion.banner}`)}
                  alt="banner"
                  className="float-end questionBanner"
                />
              </div>
            </>
          ) : (
            <p className="questionText text-start">
              {currentQuestion?.question}
            </p>
          )}
        </div>

        {currentQuestion?.imagesAnwers ? (
          <div className="answersList row mx-2">
            {currentQuestion?.answers?.map((answer, i) => {
              return <AnswersRow key={i} number={i} text={answer} horizantal />;
            })}
          </div>
        ) : (
          <div className="answersList">
            {currentQuestion?.answers?.map((answer, i) => {
              return <AnswersRow key={i} number={i} text={answer} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default G1QuestionScreen;
