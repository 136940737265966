import axios from "axios";
import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import PersonCard from "../components/PersonCard";
import { useParams } from "react-router-dom";

const IndividualGroupDisplay = () => {
  const { groupId } = useParams();
  const [players, setPlayersData] = useState([]);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/groups/${groupId}/leaderboard`,
          { headers }
        )
        .then(
          (res) => (setPlayersData(res.data.data), console.log(res.data.data))
        );
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls
        previous="/groups"
        next={`/group/${groupId}/questionStart/1`}
      />
      <div className="h-100 text-center primary-bg pt-1 groupMainContainer">
        <div className="d-flex justify-content-between">
          <h2 className="headerTitleLeft text-start ms-3">Group {groupId}</h2>
          <p className="headerTitleRight text-white me-3">8 Contestants</p>
        </div>
        <div className="row">
          {players.length > 0 ? (
            players.map((player, i) => {
              return (
                <PersonCard
                  key={i}
                  name={player.name.split("-")[0]}
                  image={player.image}
                  school={player.school}
                />
              );
            })
          ) : (
            <div className="loading"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndividualGroupDisplay;
