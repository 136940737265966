import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import axios from "axios";
import { useParams } from "react-router-dom";
import ConfettiExplosion from "canvas-confetti";

const QualificationScreen = ({ setCurrentGroup, setQuestion, setGame }) => {
  const { groupId } = useParams();

  const [gameOver, setGameOver] = useState(false);
  const [game1Over, setGame1Over] = useState(false);
  const [winner, setWinner] = useState({});

  useEffect(() => {
    ConfettiExplosion({
      startVelocity: 40,
      angle: -90,
      particleCount: 200,
      spread: 150,
      gravity: 1,
      origin: { y: -0.2 },
      scalar: 1.4,
      zIndex: 1,
    });
  }, []);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/groups/${groupId}/winner`,
          { headers }
        )
        .then((res) => setWinner(res.data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (groupId === 4) {
      setGame1Over(true);
      setGame(2);
    }
    if (groupId < 6) {
      setCurrentGroup(groupId + 1);
      setQuestion(1);
    } else {
      setGameOver(true);
    }
  }, []);

  return (
    <div className="imgContainer h-100 m-auto ">
      <HiddenControls
        previous={`/group/${groupId}/leadersboard`}
        next={
          groupId >= 4 ? "/allQualifiers" : `/group/${parseInt(groupId) + 1}`
        }
      />
      <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
        <h3 className="thinQualifiedHeader">Group {groupId}</h3>
        <h1 className="QualifiedTitle">Winner</h1>
        <div className="playerBigAvatarContaoner">
          <img
            src={winner?.image}
            mawjoude
            api
            alt="winner avatar"
            width={330}
            height={330}
          />
          <p className="QualifiedPlayerName">{winner?.name?.split("-")[0]}</p>
        </div>
      </div>
    </div>
  );
};

export default QualificationScreen;
