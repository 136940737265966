import React from "react";

const AnswersRow = ({ number, text, horizantal }) => {
  return horizantal ? (
    <div className="col-4 p-0">
      <div className="card bg-transparent border-0 m-3">
        <div className="cardImage mb-3">
          <img
            src={require(`../assets/images/game/${text}`)}
            alt={text}
            width="90%"
            className="bannersAnswers"
          />
        </div>
        <div className="cardTitle group-name-container w-75 mx-auto">
          <span>{number === 0 ? "A" : number === 1 ? "B" : "C"}</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="row pt-4 mx-2">
      <div className="col-2 p-0">
        <div className="mx-2">
          <div className="align-items-center">
            <div className="group-name-container bottomShadow bottomLightBlueShadow p-2">
              <span>{number === 0 ? "A" : number === 1 ? "B" : "C"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-10 p-0">
        <div className="mx-2">
          <div className="d-flex flex-column justify-content-center p-3 group-profiles-container bottomShadow bottomLightBlueShadow text-start">
            <p className="answerText m-0">{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswersRow;
