import React, { useEffect } from "react";
import HiddenControls from "../components/HiddenControls";
import { useParams } from "react-router-dom";

const G2QuestionNumberScreen = ({ setGame }) => {
  const { questionId } = useParams();

  const qs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  useEffect(() => {
    setGame(2);
  }, []);

  return (
    <div className="imgContainer h-100 m-auto ">
      <HiddenControls previous="" next={`/phase2/question/${questionId}`} />
      <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
        {questionId > 12 ? (
          <h1 className="questionNumberTitle mb-5  mx-auto">Bonus Question</h1>
        ) : (
          <>
            <h1 className="questionNumberTitle">Question {questionId}</h1>
            <div className="numbersRow mx-auto my-5">
              {qs.map((q, i) => {
                return (
                  <span
                    key={i}
                    className={
                      q.toString() === questionId
                        ? "text-white currentQuestionNumber"
                        : null
                    }
                  >
                    {q}
                  </span>
                );
              })}
            </div>
          </>
        )}

        <div className="timeBox bottomShadow bottomLightBlueShadow d-flex justify-content-center align-items-center p-5">
          <div className="questionTimeTxt">Hit the Buzzer</div>
        </div>
      </div>
    </div>
  );
};

export default G2QuestionNumberScreen;
