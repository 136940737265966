/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import HiddenControls from "../components/HiddenControls";
import clockIcon from "../assets/icons/clock.png";
import { useParams } from "react-router-dom";
import QUESTIONS from "../data/data";

const G1QuestionNumberScreen = ({ setQuestions }) => {
  const qs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const { groupId, questionId } = useParams();

  useEffect(() => {
    setQuestions(QUESTIONS[groupId - 1]);
  }, [groupId]);

  return (
    <div className="imgContainer h-100 m-auto ">
      <HiddenControls
        previous=""
        next={`/group/${groupId}/question/${questionId}`}
      />
      <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
        {questionId > 15 ? (
          <h1 className="questionNumberTitle mb-5 w-50 mx-auto">
            Bonus Question
          </h1>
        ) : (
          <>
            <h1 className="questionNumberTitle">Question {questionId}</h1>
            <div className="numbersRow mx-auto my-5">
              {qs.map((q, i) => {
                return (
                  <span
                    key={i}
                    className={
                      q.toString() === questionId
                        ? "text-white currentQuestionNumber"
                        : null
                    }
                  >
                    {q}
                  </span>
                );
              })}
            </div>
          </>
        )}

        <div className="timeBox bottomShadow bottomLightBlueShadow d-flex justify-content-center align-items-center p-5">
          <div className="clockIconContainer">
            <img src={clockIcon} alt="" width={80} height={80} />
          </div>
          <div className="questionTimeTxt ms-5">20 seconds</div>
        </div>
      </div>
    </div>
  );
};

export default G1QuestionNumberScreen;
