import React from "react";
import HiddenControls from "../components/HiddenControls";

const Game2IntroScreen = () => {
  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls previous="" next={`/phase2/questionStart/1`} />
      <div className="h-100 text-center primary-bg text-center">
        {/*mainContainer class*/}
        <div className="position-relative h-100 game1IntroLeft d-flex flex-column justify-content-center text-white">
          <h3 className="thinQualifiedHeader text-white">Phase 2</h3>
          <h3 className="game1description mx-auto">12 Questions</h3>
          <p className="finalPG1 w-50 mx-auto">
            Make sure you answer the correct answer before 15 seconds
          </p>
          <div className="position-absolute row game1Specs w-100 text-white">
            <div className="col-6 d-flex flex-column">
              <span className="numberSpecs mt-4">12</span>
              <span className="textSpecs mb-3">Questions</span>
            </div>
            <div className="col-6 d-flex flex-column">
              <span className="numberSpecs mt-4">4</span>
              <span className="textSpecs mb-3">Players</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game2IntroScreen;
