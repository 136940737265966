/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WelcomeScreen from "../screens/WelcomeScreen";
import GamesIntrosScreen from "../screens/GamesIntrosScreen";
import AllGroupsScreen from "../screens/AllGroupsScreen";
import IndividualGroupDisplay from "../screens/IndividualGroupDisplay";
import G1QuestionNumberScreen from "../screens/G1QuestionNumberScreen";
import G1QuestionScreen from "../screens/G1QuestionScreen";
import TimesupScreen from "../screens/TimesupScreen";
import ResultsScreen from "../screens/ResultsScreen";
import LeaderBoardScreen from "../screens/LeaderBoardScreen";
import QualificationScreen from "../screens/QualificationScreen";
import AllQualidiersScreen from "../screens/AllQualidiersScreen";
import Game2IntroScreen from "../screens/Game2IntroScreen";
import G2QuestionNumberScreen from "../screens/G2QuestionNumberScreen";
import G2QuestionScreen from "../screens/G2QuestionScreen";
import G2ScoreScreen from "../screens/G2ScoreScreen";
import WinnerScreen from "../screens/WinnerScreen";
import QUESTIONS from "../data/data";
import LoginScreen from "../screens/LoginScreen";
import FlagScreen from "../screens/FlagScreen";

const MainComponent = () => {
  const [game, setGame] = useState(1);
  const [question, setQuestion] = useState(1);
  const [currentGroup, setCurrentGroup] = useState(1);
  const [questions, setQuestions] = useState(QUESTIONS[0]);

  useEffect(() => {
    game === 2 && setQuestions(QUESTIONS[4]);
  }, [game]);

  return (
    <div className="mainCont">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/euflag" element={<FlagScreen />} />
          <Route path="/welcome" element={<WelcomeScreen />} />
          <Route exact path="/intros" element={<GamesIntrosScreen />} />
          <Route exact path="/groups" element={<AllGroupsScreen />} />
          <Route
            exact
            path="/group/:groupId"
            element={<IndividualGroupDisplay />}
          />

          <Route
            exact
            path="/group/:groupId/questionStart/:questionId"
            element={<G1QuestionNumberScreen setQuestions={setQuestions} />}
          />

          <Route
            exact
            path="/group/:groupId/question/:questionId"
            element={<G1QuestionScreen questions={questions} />}
          />
          <Route
            exact
            path="/group/:groupId/question/:questionId/timeup"
            element={<TimesupScreen />}
          />
          <Route
            exact
            path="/group/:groupId/question/:questionId/results"
            element={
              <ResultsScreen
                question={question}
                setQuestion={setQuestion}
                questions={questions}
              />
            }
          />
          <Route
            exact
            path="/group/:groupId/leadersboard"
            element={<LeaderBoardScreen draw={false} />}
          />
          <Route
            exact
            path="/group/:groupId/:questionId/leadersboard"
            element={<LeaderBoardScreen draw={false} partial={true} />}
          />
          <Route
            exact
            path="/group/:groupId/:questionId/draw/leadersboard"
            element={
              <LeaderBoardScreen currentGroup={currentGroup} draw={true} />
            }
          />
          <Route
            exact
            path="/group/:groupId/qualification"
            element={
              <QualificationScreen
                setCurrentGroup={setCurrentGroup}
                setQuestion={setQuestion}
                setGame={setGame}
              />
            }
          />
          <Route
            exact
            path="/allQualifiers"
            element={<AllQualidiersScreen />}
          />
          <Route exact path="/phase2" element={<Game2IntroScreen />} />
          <Route
            exact
            path="/phase2/questionStart/:questionId"
            element={<G2QuestionNumberScreen setGame={setGame} />}
          />
          <Route
            exact
            path="/phase2/question/:questionId"
            element={<G2QuestionScreen questions={questions} />}
          />
          <Route
            exact
            path="/phase2/question/:questionId/score"
            element={<G2ScoreScreen setQuestion={setQuestion} />}
          />
          <Route exact path="/winner" element={<WinnerScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default MainComponent;
