import React from "react";

const SmallAnswersRow = ({ number, text, horizantal, isCorrect }) => {
  return horizantal ? (
    <div className="col-4 p-0">
      <div className="card bg-transparent border-0 m-3">
        <div className="cardImage">
          <img
            src={require(`../assets/images/game/${text}`)}
            alt={text}
            width="90%"
            className="bannersAnswers"
          />
        </div>
        <div
          className="cardTitle group-name-container w-75 mx-auto"
          style={isCorrect ? { backgroundColor: "#02BD54" } : null}
        >
          <span>{number === 0 ? "A" : number === 1 ? "B" : "C"}</span>
        </div>
      </div>
    </div>
  ) : (
    <div className="row pt-3 mx-3 upper">
      <div className="col-2 p-0">
        <div className="align-items-center me-4">
          <div
            className="small-group-name-container bottomShadow bottomLightBlueShadow px-4 py-1"
            style={isCorrect ? { backgroundColor: "#02BD54" } : null}
          >
            <span>{number === 0 ? "A" : number === 1 ? "B" : "C"}</span>
          </div>
        </div>
      </div>
      <div className="col-10 p-0">
        <div className="small-group-profiles-container bottomShadow bottomLightBlueShadow text-start px-4 pt-1 ms-3">
          <span className="answerText ps-2">{text}</span>
        </div>
      </div>
    </div>
  );
};

export default SmallAnswersRow;
