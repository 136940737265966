/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import PersonCard from "../components/PersonCard";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const G2QuestionScreen = ({ questions }) => {
  const { questionId } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [players, setPlayers] = useState([]);
  const [intervalId, setIntervalId] = useState(0);
  const [pointAdded, setPointAdded] = useState(false);

  useEffect(() => {
    toast.success("Point Added Successfully!", {
      toastId: questionId,
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/buzzers/questions/${questionId}/initiate`,
          { headers }
        )
        .then((res) => console.log(res.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setCurrentQuestion(questions[questionId - 1]);
  }, [questionId, questions]);

  useEffect(() => {
    const intervalFn = () => {
      if (pointAdded === false) {
        recallTimesApi();
      }
    };
    const intervalId = setInterval(intervalFn, 2000);
    setIntervalId(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (pointAdded === true) {
      clearInterval(intervalId);
      setIntervalId(0);
    }
  }, [pointAdded]);

  const recallTimesApi = () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/buzzers/questions/${questionId}/answers`,
          {
            headers,
          }
        )
        .then((res) =>
          res.data.length > 0
            ? console.log("no players")
            : setPlayers(res.data.data)
        );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {pointAdded && <ToastContainer />}

      <div className="imgContainer h-100 m-auto">
        <HiddenControls
          previous=""
          next={`/phase2/question/${questionId}/score`}
        />
        <div className="h-100 text-center primary-bg pt-4 mainContainer text-center">
          <div className="d-flex justify-content-between mx-5 mt-2">
            <h2 className="headerQuestionTitleLeft text-start">
              Question {questionId}
            </h2>
          </div>
          <p className="questionText mx-5 my-2 text-start">
            {currentQuestion.question}
          </p>
          <div className="d-flex flex-wrap mt-5 mx-5">
            {players &&
              players?.map((player, i) => {
                return (
                  <PersonCard
                    key={i}
                    inG2={true}
                    winner={false}
                    id={player.id}
                    q={questionId}
                    name={player.student}
                    school={player.school}
                    time={player.duration}
                    image={player.image}
                    pointAdded={pointAdded}
                    setPointAdded={setPointAdded}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default G2QuestionScreen;
