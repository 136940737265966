import React from "react";
import HiddenControls from "../components/HiddenControls";

const GamesIntrosScreen = () => {
  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls previous="/welcome" next="/groups" />
      <div className="h-100 text-center primary-bg text-center row m-0">
        <div className="position-relative col-7 p-0 game1IntroLeft d-flex flex-column justify-content-center text-white">
          <h3 className="thinQualifiedHeader text-white">Phase 1</h3>
          <h3 className="game1description mx-auto">
            15 Multiple Choice Questions
          </h3>
          <p className="finalPG1">You have 20 seconds to choose an answer</p>
          <div className="position-absolute row game1Specs w-100 text-white">
            <div className="p-0 col-4 d-flex flex-column">
              <span className="numberSpecs mt-4">4</span>
              <span className="textSpecs mb-3">Groups</span>
            </div>
            <div className="p-0 col-4 d-flex flex-column">
              <span className="numberSpecs mt-4">15</span>
              <span className="textSpecs mb-3">Questions</span>
            </div>
            <div className="p-0 col-4 d-flex flex-column">
              <span className="numberSpecs mt-4">32</span>
              <span className="textSpecs mb-3">Players</span>
            </div>
          </div>
        </div>
        <div className="p-0 col-5 position-relative game2IntroRight text-white d-flex flex-column justify-content-center">
          <h3 className="thinQualifiedHeader">Phase 2</h3>
          <h3 className="game2description mx-auto">12 Questions</h3>
          <p className="finalPG1 mx-4">
            Hit your buzzer as fast as you can to answer first
          </p>
          <div className="position-absolute row game1Specs w-100 text-white">
            <div className="col-6 p-0 d-flex flex-column">
              <span className="numberSpecs mt-4">12</span>
              <span className="textSpecs mb-3">Questions</span>
            </div>
            <div className="col-6 p-0 d-flex flex-column">
              <span className="numberSpecs mt-4">4</span>
              <span className="textSpecs mb-3">Players</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamesIntrosScreen;
