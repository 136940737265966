/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import React, { useState } from "react";
import man1 from "../assets/images/avatars/man.png";

const PersonCard = ({
  name,
  school,
  id,
  q,
  image,
  inG2,
  winner,
  time,
  setPointAdded,
  pointAdded,
}) => {
  const [cardClicked, setCardClicked] = useState(false);

  const handleAddPointToPlayer = () => {
    setCardClicked(true);
    setTimeout(() => {
      setCardClicked(false);
    }, 500);
    if (!pointAdded) {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/admin/v1/students/${id}/2/${q}/points/add`,
            {
              headers,
            }
          )
          .then((res) => setPointAdded(true));
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div
      className={
        cardClicked
          ? "col-3 p-0 personsCardContainer cardClicked"
          : "col-3 p-0 personsCardContainer"
      }
      onClick={() => inG2 && handleAddPointToPlayer()}
    >
      <div className="mx-auto">
        <div className="bg-white my-2 py-1 mx-1 personsCard bottomShadow bottomLightBlueShadow text-center">
          <img
            src={image ? image : man1}
            alt="man image"
            className="playerImage my-1"
          />
          <h2 className="d-flex flex-column justify-content-center">
            {name ? name : "Long Player Name Here"}
          </h2>
          <h3>{school ? school : "School Name Here"}</h3>
        </div>
        {inG2 === true && (
          <p
            className={
              winner
                ? "g2PlayerTime g2WinnerPlayerTime mt-4"
                : "g2PlayerTime mt-4"
            }
          >
            {time.toFixed(2)}
          </p>
        )}
      </div>
    </div>
  );
};

export default PersonCard;
