import React from "react";

const PlayerResultCard = ({ id, answer, name, isCorrect, image }) => {
  return (
    <div className="d-flex pt-2 playerResultCentering">
      <div className="align-items-center me-2 mb-2">
        <div
          className={
            isCorrect !== 0
              ? "med-group-name-container bottomShadow bottomGreenShadow px-4 py-2 correctAnswerBg"
              : "med-group-name-container bottomShadow bottomRedShadow px-4 py-2"
          }
        >
          <span
            className={
              isCorrect !== 0
                ? "correctAnswerTextColor resulstAnswerLetter"
                : "wrongAnswerLetterColor resulstAnswerLetter"
            }
          >
            {answer === 1 ? "A" : answer === 2 ? "B" : answer === 3 ? "C" : "-"}
          </span>
        </div>
      </div>
      <div className="w-100">
        <div
          className={
            isCorrect !== 0
              ? "med-group-profiles-container text-start px-1 pt-2 correctAnswerBg"
              : "med-group-profiles-container text-start px-1 pt-2"
          }
        >
          <div className="d-flex align-items-center">
            <div className="avatarPlaceHolder">
              <img src={image} alt="avatar" />
            </div>
            <span
              className={
                isCorrect !== 0
                  ? "correctAnswerTextColor resulstAnswerName ms-2 "
                  : "wrongAnswerNameColor resulstAnswerName ms-2 "
              }
            >
              {name.split("-")[0]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerResultCard;
