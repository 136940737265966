const QUESTIONS = [
  [
    {
      id: 1,
      question: "What is the capital of Cyprus?",
      withBanner: false,
      banner: "",
      answers: ["Limassol", "Larnaca", "Nicosia"],
      correct: 2,
    },
    {
      id: 2,
      question: "Which country does this flag belong to?",
      withBanner: true,
      banner: "flag.jpg",
      answers: ["Slovakia", "Slovenia", "Czech Republic"],
      correct: 2,
    },
    {
      id: 3,
      question: "Who is the current President of the European Commission?",
      withBanner: false,
      banner: "",
      answers: ["Josep Borrell", "Ursula Von Der Leyen", "José Manuel Barroso"],
      correct: 1,
    },
    {
      id: 4,
      question:
        "How many EU countries use the EURO as their official currency?",
      withBanner: false,
      banner: "",
      answers: ["15", "19", "22"],
      correct: 1,
    },
    {
      id: 5,
      question:
        "In which EU country are the following cities located: Gouda-Leiden-Utrecht?",
      withBanner: false,
      banner: "",
      answers: ["Germany ", "Belgium", "The Netherlands"],
      correct: 2,
    },
    {
      id: 6,
      question:
        "When did France most recently take on the Presidency of the Council of the European Union?",
      withBanner: false,
      banner: "",
      answers: ["January 2021", "July 2021", "January 2022"],
      correct: 2,
    },
    {
      id: 7,
      question:
        "What was the approximate population of the European Union in 2021?",
      withBanner: false,
      banner: "",
      answers: ["350 million", "450 million", "500 million"],
      correct: 1,
    },
    {
      id: 8,
      question:
        "Which were the original 6 countries of what is now known as the European Union?",
      withBanner: false,
      banner: "",
      answers: [
        "France, Italy, Germany, Luxembourg, the Netherlands, and Spain",
        "Belgium, France, Italy, Luxembourg, the Netherlands, and Germany",
        "Belgium, France, UK, Luxembourg, the Netherlands, and Germany",
      ],
      correct: 1,
    },
    {
      id: 9,
      question:
        "By how much does the European Union plan to cut greenhouse gas emissions by 2030?",
      withBanner: false,
      banner: "",
      answers: ["40%", "45%", "55%"],
      correct: 2,
    },
    {
      id: 10,
      question:
        "Which of the following country last joined the European Union?",
      withBanner: false,
      banner: "",
      answers: ["Bulgaria", "Romania", "Croatia"],
      correct: 2,
    },
    {
      id: 11,
      question: "What year was the Schengen treaty signed?",
      withBanner: false,
      banner: "",
      answers: ["1985", "1992", "1993"],
      correct: 0,
    },
    {
      id: 12,
      question:
        "Europe Day is celebrated on May 9. Which event does it commemorate?",
      withBanner: false,
      banner: "",
      answers: [
        "The advent of the EURO in 2002",
        "A 1950 declaration by Robert Schuman",
        "The first European Parliament elections in 1979",
      ],
      correct: 1,
    },
    {
      id: 13,
      question:
        "Where is the only Tennis Grand Slam tournament on EU soil played?",
      withBanner: false,
      banner: "",
      answers: ["Grass", "Clay", "Hardcourt"],
      correct: 1,
    },
    {
      id: 14,
      question: "Where is Comté cheese from?",
      withBanner: false,
      banner: "",
      answers: ["Switzerland", "Belgium", "France"],
      correct: 2,
    },
    {
      id: 15,
      question: "Who wrote Madame Bovary ?",
      withBanner: false,
      banner: "",
      answers: ["Victor Hugo", "Stendhal", "Gustave Flaubert"],
      correct: 2,
    },
    {
      id: 16,
      question: "What does EEAS stand for?",
      withBanner: false,
      banner: "",
      answers: [
        "European Economic Action Service",
        "European Executive Agency for Space",
        "European External Action Service",
      ],
      correct: 2,
    },
    {
      id: 17,
      question:
        "Who is this former High Representative of the EU for Foreign Affairs and Security Policy?",
      withBanner: true,
      banner: "deplomatic.jpg",
      answers: [
        "Catherine Ashton",
        "Federica Mogherini",
        "Benita Ferrero-Waldner",
      ],
      correct: 1,
    },
    {
      id: 18,
      question:
        "Which award is given every year by the European Union to promote freedom of thought and expression?",
      withBanner: false,
      banner: "",
      answers: [
        "The World Press Freedom Prize",
        "The CPJ International Press Freedom Awards",
        "The Samir Kassir Award",
      ],
      correct: 2,
    },
  ],
  [
    {
      id: 1,
      question:
        "Which of these cities is not home to an EU institution or agency?",
      withBanner: false,
      banner: "",
      answers: ["Geneva", "The Hague", "Luxembourg"],
      correct: 0,
    },
    {
      id: 2,
      question:
        "What was the country of origin of the inventor of the Rubik's cube?",
      withBanner: false,
      banner: "",
      answers: ["Romania", "Bulgaria", "Hungary"],
      correct: 2,
    },
    {
      id: 3,
      question: "Who is this person?",
      withBanner: true,
      banner: "manonhorse.jpg",
      answers: ["Marquis de Lafayette", "Napoleon Bonaparte", "Julius Cesar"],
      correct: 1,
    },
    {
      id: 4,
      question: "When was the Lisbon Treaty signed?",
      withBanner: false,
      banner: "",
      answers: ["2003 ", "2007", "2012"],
      correct: 1,
    },
    {
      id: 5,
      question: "What was the European Union called in the past?",
      withBanner: false,
      banner: "",
      answers: [
        "European Economic Commission",
        "European Parliament",
        "European Economic Community",
      ],
      correct: 2,
    },
    {
      id: 6,
      question: "Who was Robert Schuman?",
      withBanner: false,
      banner: "",
      answers: [
        "A German Chancellor",
        "A French Foreign Minister",
        "A Western German Minister of Economy",
      ],
      correct: 0,
    },
    {
      id: 7,
      question:
        "In which EU country are the following cities located: Mosta - Valletta - Gozo?",
      withBanner: false,
      banner: "",
      answers: ["Malta", "Italy", "Spain"],
      correct: 0,
    },
    {
      id: 8,
      question: "Who wrote Don Quixote?",
      withBanner: false,
      banner: "",
      answers: ["Molière  ", "Gabriel García Márquez ", "Miguel de Cervantes"],
      correct: 2,
    },
    {
      id: 9,
      question: "Which of these countries isn't landlocked?",
      withBanner: false,
      banner: "",
      answers: ["Czech Republic", "Hungary", "Lithuania"],
      correct: 2,
    },
    {
      id: 10,
      question: "What country is the band ABBA from?",
      withBanner: false,
      banner: "",
      answers: ["Sweden", "Denmark", "France"],
      correct: 0,
    },
    {
      id: 11,
      question: "Which is the oldest university in the EU?",
      withBanner: false,
      banner: "",
      answers: [
        "University of Bologna",
        "Sorbonne University",
        "Trinity College Dublin",
      ],
      correct: 0,
    },
    {
      id: 12,
      question: "Which of these 3 flags is Luxembourg's?",
      withBanner: false,
      banner: "",
      imagesAnwers: true,
      answers: ["f1.jpg", "f2.jpg", "f3.jpg"],
      correct: 0,
    },
    {
      id: 13,
      question: "Which city will host the 2024 Summer Olympics?",
      withBanner: false,
      banner: "",
      answers: ["Lisbon", "Berlin", "Paris"],
      correct: 2,
    },
    {
      id: 14,
      question: "What country is the Spa Francorchamps Fomula 1 track located?",
      withBanner: false,
      banner: "",
      answers: ["France", "Belgium", "Luxembourg"],
      correct: 1,
    },
    {
      id: 15,
      question: "What is the name of the King of Sweden?",
      withBanner: false,
      banner: "",
      answers: ["Willem-Alexander", "Harald V", "Carl XVI Gustaf"],
      correct: 2,
    },
    {
      id: 16,
      question:
        "What is the name of the EU Special Representative (EUSR) for the Middle East Peace Process?",
      withBanner: false,
      banner: "",
      answers: ["Fernando Gentilini", "Eamon Gilmore", "Sven Koopmans"],
      correct: 2,
    },
    {
      id: 17,
      question:
        "Where in Cyprus does the legend say that the Goddess Aphrodite was born?",
      withBanner: false,
      banner: "",
      answers: ["Larnaca", "Paphos", "Famagusta"],
      correct: 1,
    },
    {
      id: 18,
      question: "Skoda is an automobile manufacturer from?",
      withBanner: false,
      banner: "",
      answers: ["Slovakia", "Czech Republic", "Romania"],
      correct: 1,
    },
  ],
  [
    {
      id: 1,
      question: "What city are you in if you are eating this?",
      withBanner: true,
      banner: "food.jpg",
      answers: ["Madrid", "Bilbao", "Lisbon"],
      correct: 2,
    },
    {
      id: 2,
      question: "Between which countries does the Adriatic Sea flow?",
      withBanner: false,
      banner: "",
      answers: ["Italy and France", "Greece and Turkey", "Croatia and Italy"],
      correct: 2,
    },
    {
      id: 3,
      question:
        "In which EU country are the following cities: Turku - Tampere – Hailuoto?",
      withBanner: false,
      banner: "",
      answers: ["Denmark", "Sweden", "Finland"],
      correct: 2,
    },
    {
      id: 4,
      question: "When did the European Union receive a Nobel Peace Prize?",
      withBanner: false,
      banner: "",
      answers: ["1995 ", "2012", "2017"],
      correct: 1,
    },
    {
      id: 5,
      question: "Which French President served the longest in office?",
      withBanner: false,
      banner: "",
      answers: ["Charles De Gaulle", "François Mitterrand", "Jacques Chirac"],
      correct: 1,
    },
    {
      id: 6,
      question: "Which country won the Football World Cup in 2014?",
      withBanner: false,
      banner: "",
      answers: ["France", "Germany", "Italy"],
      correct: 1,
    },
    {
      id: 7,
      question:
        "What is the name of the EU's own global satellite navigation system?",
      withBanner: false,
      banner: "",
      answers: ["GPS", "Copernicus", "Galileo"],
      correct: 2,
    },
    {
      id: 8,
      question: "In what city is the following landmark located?",
      withBanner: true,
      banner: "landmark.jpg",
      answers: ["Paris ", "Athens", "Rome"],
      correct: 2,
    },
    {
      id: 9,
      question: "What is the capital of Romania?",
      withBanner: false,
      banner: "",
      answers: ["Budapest", "Bucharest", "Sofia"],
      correct: 1,
    },
    {
      id: 10,
      question: "Which of these is not an EU Treaty?",
      withBanner: false,
      banner: "",
      answers: ["Treaty of Nice", "Treaty of Lisbon", "Treaty of Malta"],
      correct: 2,
    },
    {
      id: 11,
      question: "Which country does this flag belong to?",
      withBanner: true,
      banner: "bulg.jpg",
      answers: ["Bulgaria", "Slovenia", "Romania"],
      correct: 0,
    },
    {
      id: 12,
      question: "What is the name of the current EU Ambassador to Lebanon?",
      withBanner: false,
      banner: "",
      answers: ["Andreas Kindl", "Ralph Tarraf", "Jesus Santos"],
      correct: 1,
    },
    {
      id: 13,
      question: "What is the highest mountain peak in the EU?",
      withBanner: false,
      banner: "",
      answers: ["Mount Elbrus", "Mount Etna", "Mont Blanc"],
      correct: 0,
    },
    {
      id: 14,
      question:
        "Which of these opera singers was not part of the Three Tenors?",
      withBanner: false,
      banner: "",
      answers: ["Jose Carreras", "Placido Domingo", "Andrea Bocelli"],
      correct: 2,
    },
    {
      id: 15,
      question: "What year did the Roman Empire fall?",
      withBanner: false,
      banner: "",
      answers: ["136 BC", "476 AD", "1054 AD"],
      correct: 1,
    },
    {
      id: 16,
      question:
        "The EU now has a roadmap to make the economy sustainable and act for climate, do you know what it's called?",
      withBanner: false,
      banner: "",
      answers: ["Green New Deal", "EU for Climate", "European Green Deal"],
      correct: 2,
    },
    {
      id: 17,
      question: "The European Year's theme for 2022 is?",
      withBanner: false,
      banner: "",
      answers: ["Youth", "Volunteering", "Cultural Heritage"],
      correct: 0,
    },
    {
      id: 18,
      question: "Which is not an EU prize?",
      withBanner: false,
      banner: "",
      answers: ["Charlemagne Prize", "Newton Prize", "Sakharov Prize"],
      correct: 1,
    },
  ],
  [
    {
      id: 1,
      question: "Which country are the Faroe Islands part of?",
      withBanner: false,
      banner: "",
      answers: ["Denmark", "Croatia", "Greece"],
      correct: 0,
    },
    {
      id: 2,
      question: "What was the nationality of Vincent Van Gogh?",
      withBanner: false,
      banner: "",
      answers: ["German", "Dutch", "Danish"],
      correct: 1,
    },
    {
      id: 3,
      question: "Who painted the Mona Lisa?",
      withBanner: false,
      banner: "",
      answers: ["Michelangelo", "Raphael", "Leonardo Da Vinci"],
      correct: 2,
    },
    {
      id: 4,
      question: "What is the European Union motto?",
      withBanner: false,
      banner: "",
      answers: [
        "United and Equal",
        "Equality, Freedom, and Diversity",
        "United in Diversity",
      ],
      correct: 2,
    },
    {
      id: 5,
      question: "Where is this?",
      withBanner: true,
      banner: "city.jpg",
      answers: ["Rome", "Florence", "Venice"],
      correct: 1,
    },
    {
      id: 6,
      question: "How many MPs make up the European Union Parliament?",
      withBanner: false,
      banner: "",
      answers: ["350", "508", "705"],
      correct: 2,
    },
    {
      id: 7,
      question: "Who wrote Les Misérables?",
      withBanner: false,
      banner: "",
      answers: ["Voltaire", "Emile Zola", "Victor Hugo"],
      correct: 2,
    },
    {
      id: 8,
      question: "Which one of these is not an EU institution?",
      withBanner: false,
      banner: "",
      answers: [
        "European Central Bank",
        "European Court of Auditors",
        "Council of Europe",
      ],
      correct: 0,
    },
    {
      id: 9,
      question: "Which countries are part of the Iberian Peninsula?",
      withBanner: false,
      banner: "",
      answers: ["Italy and Croatia", "Spain and Portugal", "Spain and France"],
      correct: 1,
    },
    {
      id: 10,
      question:
        "What is the name of the European Union student exchange programme established in 1987?",
      withBanner: false,
      banner: "",
      answers: ["Cervantes", "Erasmus", "Goethe"],
      correct: 0,
    },
    {
      id: 11,
      question: "What is the name of the current French Ambassador to Lebanon?",
      withBanner: false,
      banner: "",
      answers: ["Anne Grillo", "Brigitte Curmi", "Marlène Schiappa"],
      correct: 0,
    },
    {
      id: 12,
      question: "What sport did gold medalist Alberto Tomba practice?",
      withBanner: false,
      banner: "",
      answers: ["Ice skating", "Downhill skiing", "Luge"],
      correct: 1,
    },
    {
      id: 13,
      question: "To whom is the sentence 'Veni, Vidi, Vici' attributed?",
      withBanner: false,
      banner: "",
      answers: ["Vito Corleone", "Marcus Aurelius", "Julius Caesar"],
      correct: 2,
    },
    {
      id: 14,
      question: "Who is this?",
      withBanner: true,
      banner: "woman.jpg",
      answers: ["Sofia Loren", "Claudia Cardinale", "Monica Bellucci"],
      correct: 0,
    },
    {
      id: 15,
      question: "What is the capital of Estonia?",
      withBanner: false,
      banner: "",
      answers: ["Vilnius", "Riga", "Tallinn"],
      correct: 2,
    },
    {
      id: 16,
      question: "Who is this famous painter?",
      withBanner: true,
      banner: "painter.jpg",
      answers: ["Pablo Picasso", "Salvador Dali", "El Greco"],
      correct: 1,
    },
    {
      id: 17,
      question: "Which Football team won the most European Champion Leagues?",
      withBanner: false,
      banner: "",
      answers: ["Barcelona", "Real Madrid", "Juventus"],
      correct: 1,
    },
    {
      id: 18,
      question: "Where was novelist Franz Kafka born?",
      withBanner: false,
      banner: "",
      answers: ["Hungary", "Germany", "Czech Republic"],
      correct: 2,
    },
  ],
  [
    {
      id: 1,
      question: "Name two landlocked countries in the European Union.",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 2,
      question: "Who won the largest number of Tennis Grand Slam Titles?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 3,
      question: "What country has Ljubljana as it capital?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 4,
      question: "Name 2 EU countries that the Danube river runs through",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 5,
      question:
        "Of the Baltic States, Latvia, Estonia & Lithuania, which is the furthest north?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 6,
      question: "6-	Name an EU country with an animal on its national flag.",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 7,
      question: "How many European Union Member States are there?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 8,
      question:
        "Who was reelected President of France for a second term on April 24th, 2022?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 9,
      question:
        "Who composed the Ode to Joy, which the EU uses as its anthem? ",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 10,
      question: "Which country are the Canary Islands part of?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 11,
      question: "Which color is to the right in the French flag?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 12,
      question: "Name a musical composer who was deaf.",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 13,
      question: "What is the Capital of Austria?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 14,
      question: "When did the Battle of France take place?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 15,
      question: "In which city can you find the Manneken Pis?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 16,
      question: "In which country are the Dolomites mountains located?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 17,
      question: "Which of the following is the nickname of Europe?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 18,
      question: "From which part of Europe did The Vikings originate?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 19,
      question: "In which country did the Renaissance start?",
      withBanner: false,
      banner: "",
      answers: [],
    },
    {
      id: 20,
      question: "In which country is Brie cheese produced?",
      withBanner: false,
      banner: "",
      answers: [],
    },
  ],
];

export default QUESTIONS;
