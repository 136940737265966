import React from "react";

const GroupProfilesRow = ({ group }) => {
  return (
    <div className="d-flex justify-content-center pt-5 mx-5">
      <div className="align-items-center mx-4 ">
        <div className="group-name-container bottomShadow bottomLightBlueShadow px-4 py-2">
          <span>G{group[0]}</span>
        </div>
      </div>
      <div>
        <div className="group-profiles-container bottomShadow bottomLightBlueShadow d-flex justify-content-center align-items-center px-5">
          {group[1].student.map((s, i) => {
            return (
              <img key={i} src={s.image} alt="avatar" className="avatar" />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupProfilesRow;
