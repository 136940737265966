import React from "react";
import euFlag from "../assets/images/euFlag.jpg";
import HiddenControls from "../components/HiddenControls";

const FlagScreen = () => {
  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls previous="" next="/welcome" />
      <div className="h-100 text-center primary-bg mainContainer text-center">
        <img src={euFlag} width="100%" height="100%" alt="EU flag" />
      </div>
    </div>
  );
};

export default FlagScreen;
