import React, { useEffect } from "react";

const PlayerScoreAndRankCard = ({
  rank,
  name,
  score,
  image,
  highScore,
  draw,
}) => {
  useEffect(() => {
    console.log(draw);
  }, [draw]);
  return (
    <div
      className="d-flex pt-3 mx-5 justify-content-center"
      style={
        draw === true && highScore === score
          ? { opacity: 1 }
          : draw === true && score < highScore
          ? { opacity: 0.5 }
          : draw === false
          ? { opacity: 1 }
          : null
      }
    >
      <div className="mx-4">
        <div className="playerRank bottomShadow bottomLightBlueShadow px-2">
          <span>{rank}</span>
        </div>
      </div>
      <div>
        <div className="playerScoreAndRank bottomShadow bottomLightBlueShadow text-start px-4 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="thinAvatarPlaceHolder me-2">
              <img src={image} alt="avatar" />
            </div>
            <span>{name.split("-")[0]}</span>
          </div>
          <span>Score: {score}</span>
        </div>
      </div>
    </div>
  );
};

export default PlayerScoreAndRankCard;
