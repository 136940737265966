import axios from "axios";
import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import PlayerResultCard from "../components/PlayerResultCard";
import { useParams } from "react-router-dom";
import SmallAnswersRow from "../components/SmallAnswersRow";

const ResultsScreen = ({ question, setQuestion, questions }) => {
  const { groupId, questionId } = useParams();

  const [game1Finished, setGame1Finished] = useState(false);
  const [draw, setDraw] = useState(false);
  const [nextQuestion, setNextQuestion] = useState(0);
  const [playersAnswers, setPlayersAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/groups/${groupId}/questions/${questionId}/answers`,
          { headers }
        )
        .then((res) => setPlayersAnswers(res.data.data));
    } catch (err) {
      console.log(err);
    }

    if (questionId >= 15) {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/admin/v1/groups/${groupId}/winner`,
            { headers }
          )
          .then(
            (res) => (
              setDraw(res.data.draw), setNextQuestion(res.data.next_question)
            )
          );
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  useEffect(() => {
    setCurrentQuestion(questions[questionId - 1]);
  }, [questionId, questions]);

  useEffect(() => {
    let q = parseInt(questionId);
    if (!draw && q === 15) {
      setGame1Finished(true);
    } else if (q === 18) {
      setGame1Finished(true);
    } else if (draw) {
      setQuestion(question + 1);
      setGame1Finished(false);
    } else if (!draw && q < 15) {
      setQuestion(question + 1);
      setGame1Finished(false);
    } else if (!draw && q > 15) {
      setGame1Finished(true);
    }
  }, [draw, nextQuestion, questionId]);

  return (
    <div className="imgContainer h-100 m-auto ">
      <HiddenControls
        previous=""
        next={
          parseInt(questionId) < 15 && parseInt(questionId) % 5 === 0
            ? `/group/${groupId}/${questionId}/leadersboard`
            : game1Finished
            ? `/group/${groupId}/leadersboard`
            : draw
            ? `/group/${groupId}/${questionId}/draw/leadersboard`
            : `/group/${groupId}/questionStart/${parseInt(questionId) + 1}`
        }
      />
      <div className="h-100 text-center primary-bg mainContainer text-center">
        <div>
          <h2 className="smallHeaderQuestionTitleLeft ms-4 text-start">
            Question {questionId}
          </h2>
          <div className="row mx-2">
            {currentQuestion?.withBanner ? (
              <>
                <p className="col-8 p-0 smallQuestionText text-start">
                  {currentQuestion?.question}
                </p>
                <div className="col-4 p-0">
                  <img
                    src={require(`../assets/images/game/${currentQuestion.banner}`)}
                    alt="banner"
                    className="float-end smallQuestionBanner"
                  />
                </div>
              </>
            ) : (
              <p className="smallQuestionText text-start">
                {currentQuestion?.question}
              </p>
            )}
          </div>

          {currentQuestion?.imagesAnwers ? (
            <div className="row mx-5">
              {currentQuestion?.answers?.map((answer, i) => {
                return (
                  <SmallAnswersRow
                    key={i}
                    number={i}
                    text={answer}
                    horizantal
                  />
                );
              })}
            </div>
          ) : (
            <div>
              {currentQuestion?.answers?.map((answer, i) => {
                return (
                  <SmallAnswersRow
                    key={i}
                    number={i}
                    text={answer}
                    isCorrect={currentQuestion.correct === i ? true : false}
                  />
                );
              })}
            </div>
          )}
        </div>
        <div className="m-0 row mx-2">
          {playersAnswers.length < 7 ? (
            <div className="loading"></div>
          ) : (
            playersAnswers.map((person, i) => {
              return (
                <div className="col-6" key={i}>
                  <PlayerResultCard
                    id={i + 1}
                    answer={person.answer}
                    name={person.student}
                    isCorrect={person.status}
                    image={person.image}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsScreen;
