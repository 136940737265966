import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import axios from "axios";
import ConfettiExplosion from "canvas-confetti";

const WinnerScreen = () => {
  const [players, setPlayers] = useState({});
  const [winner, setWinner] = useState({});

  useEffect(() => {
    ConfettiExplosion({
      startVelocity: 40,
      angle: -90,
      particleCount: 200,
      spread: 150,
      gravity: 1,
      origin: { y: -0.2 },
      scalar: 1.4,
      zIndex: 1,
    });
  }, []);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/admin/v1/leaderboard`, {
          headers,
        })
        .then((res) => res.data.success === true && setPlayers(res.data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setWinner(players[0]);
  }, [players]);

  return (
    <div className="imgContainer h-100 m-auto">
      {/* <HiddenControls previous="" next="" /> */}
      <div className="h-100 text-center primary-bg pt-4 mainContainer">
        <h1 className="winnerTitleText my-5">Winner!</h1>
        <div className="playerBigAvatarContaoner">
          <img
            src={winner?.image}
            alt="winner avatar"
            width={330}
            height={330}
          />
          <p className="QualifiedPlayerName">{winner?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default WinnerScreen;
