/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import HiddenControls from "../components/HiddenControls";
import PlayerScoreAndRankCard from "../components/PlayerScoreAndRankCard";
import { useParams } from "react-router-dom";

const LeaderBoardScreen = ({ draw, partial }) => {
  const { groupId, questionId } = useParams();
  const [playersData, setPlayersData] = useState([]);
  const [highScore, setHighScore] = useState(0);

  useEffect(() => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/v1/groups/${groupId}/leaderboard`,
          { headers }
        )
        .then((res) => (console.log(res.data), setPlayersData(res.data.data)));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    playersData.length > 0 && setHighScore(playersData[0].score);
  }, [playersData]);

  return (
    <div className="imgContainer h-100 m-auto">
      <HiddenControls
        previous=""
        next={
          partial
            ? `/group/${groupId}/questionStart/${parseInt(questionId) + 1}`
            : draw
            ? `/group/${groupId}/questionStart/${parseInt(questionId) + 1}`
            : `/group/${groupId}/qualification`
        }
      />
      <div className="h-100 text-center primary-bg pt-4 mainContainer">
        {playersData.map((player, i) => {
          return (
            <PlayerScoreAndRankCard
              key={i}
              rank={i + 1}
              name={player.name.split("-")[0]}
              score={player.score}
              image={player.image}
              draw={draw}
              highScore={highScore && highScore}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LeaderBoardScreen;
